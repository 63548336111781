<template>
  <div class="console">
    THANK YOU , WELCOME TO HERE @
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
  .console {
    font-size: 30px;
    text-align: center;
    margin-top: 350px;
  }
</style>
