<template>
  <div class="float-box">
    <div class="content-wrapper">
      <div v-if="title" class="row-center float-box-header jc-sb">
        <h2>{{ title }}</h2>
        <slot name="header-extra"> </slot>
      </div>
      <div class="slot-wrapper" :style="contentStyle">
        <slot></slot>
      </div>
    </div>
    <span class="top-left"></span>
    <span class="top-right"></span>
    <span class="bottom-left"></span>
    <span class="bottom-right"></span>
  </div>
</template>

<script>
export default {
  name: "FloatCard",
  props: {
    title: {
      type: String,
    },
    contentHeight: {
      type: [Number, String],
      default: 300,
    },
  },
  computed: {
    contentStyle() {
      if (this.contentHeight === "auto") {
        return {
          height: "auto",
        };
      }
      return {
        height: this.contentHeight + "px",
      };
    },
  },
};
</script>

<style lang="scss">
.float-box {
  width: 100%;
  padding: 10px 6px;
  position: relative;

  &-header {
    margin-bottom: 10px;
    // background: url("../../../../assets/images/data-view/float-box-header-bg.png")
    // no-repeat;
    background-size: 100% 100%;
    padding: 14px 0;
    border-bottom: 1px solid #f5f5f580;
    h2 {
      font-size: 18px;
      margin-left: 16px;
      font-weight: 600;
      color: #49c0ec;
      // background: -webkit-gradient(
      //   linear,
      //   left top,
      //   left bottom,
      //   from(#fff),
      //   color-stop(15%, #fff),
      //   to(#2d8ab7)
      // );
      // background: linear-gradient(180deg, #fff, #fff 15%, #2d8ab7);
      // -webkit-background-clip: text;
      // background-clip: text;
      // -webkit-text-fill-color: transparent;
    }
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    background-color: #09162980;
    border-radius: 2px;
    backdrop-filter: blur(6px);
    // box-shadow: 11px 7px 22px 6px rgba(9, 22, 41, 0.72);
  }

  .slot-wrapper {
    padding: 10px 16px;
  }
}

.top-left {
  position: absolute;
  left: -2px;
  top: -2px;
  padding: 6px;
  border-style: solid;
  border-color: #02a6b5;
  border-width: 2px 0 0 2px;
}
.top-right {
  position: absolute;
  right: -2px;
  top: -2px;
  padding: 6px;
  border-style: solid;
  border-color: #02a6b5;
  border-width: 2px 2px 0 0;
}
.bottom-left {
  position: absolute;
  bottom: -1px;
  left: -2px;
  padding: 6px;
  border-style: solid;
  border-color: #02a6b5;
  border-width: 0 0 2px 2px;
}
.bottom-right {
  position: absolute;
  bottom: -2px;
  right: -2px;
  padding: 6px;
  border-style: solid;
  border-color: #02a6b5;
  border-width: 0 2px 2px 0;
}
</style>
