<template>
  <div class="header-box">
    <el-row type="flex" justify="space-between">
      <el-col :span="8" align="left">
        <el-row :gutter="24">
          <el-col :span="8" style="padding-top: 46px">
            <span
              style="
                color: #4ec39a;
                font-weight: bold;
                font-size: 38px !important;
              "
              >{{ currentTime }}</span
            >
          </el-col>
          <el-col :span="8" :offset="1" style="padding-top: 48px">
            <el-row>
              <span style="color: #4ec39a; font-weight: bold"
                >{{ Utils.todayDate() }}
              </span>
            </el-row>
            <el-row>
              <span style="color: #4ec39a; font-weight: bold"
                >{{ Utils.todayWeek() }}
              </span>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="8" class="titleText" style="padding-top: 20px">
        <span class="title">山东大学智慧校园大数据平台</span>
      </el-col>
      <el-col
        :span="8"
        align="right"
        style="padding-top: 48px; padding-right: 10px"
      >
        <el-row :gutter="24">
          <el-col :span="10" :offset="14">
            <el-button type="primary" size="big" @click="jumpTo">
              切换/操作
            </el-button>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-dialog
      title="便签"
      width="400px"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      @closed="saveNotes"
    >
      <el-input
        type="textarea"
        :rows="7"
        placeholder="请输入内容"
        v-model="notes"
      >
      </el-input>
    </el-dialog>
  </div>
</template>

<script>
import Utils from "@/common/utils";
export default {
  inject: ["reload"],
  data() {
    return {
      currentTime: "",
      isCollapse: false,
      dialogVisible: false,
      notes:
        localStorage.getItem("notes") ||
        "便签中的内容会存储在本地，这样即便你关掉了浏览器，在下次打开时，依然会读取到上一次的记录。是个非常小巧实用的本地备忘录",
    };
  },
  mounted() {
    this.updateTime();
    setInterval(this.updateTime, 1000);
  },
  methods: {
    jumpTo() {
      const url = "https://kunlun2.hellobike.com/#/new-board";
      window.open(url);
    },
    updateTime() {
      let _this = this;
      _this.currentTime = Utils.nowTime();
    },
    collapse() {
      this.$alert("跳转", "跳转", {
        confirmButtonText: "确定",
        callback: (action) => {
          this.$message({
            type: "info",
            message: `action: ${action}`,
          });
        },
      });
    },
    refresh() {
      this.reload();
    },
    saveNotes() {
      localStorage.setItem("notes", this.notes);
    },
    handleCommand(command) {
      switch (command) {
        case "logout":
          this.$router.replace({ path: "/login" });
          break;
      }
    },
  },
  computed: {
    username() {
      let { username } = JSON.parse(sessionStorage.getItem("user"));
      return username.toUpperCase();
    },
  },
};
</script>
<style scoped>
/* .el-col .el-col-6{
  width: 30%;
} */
</style>

<style lang="scss" scoped>
.header-box {
  position: relative;
  background-image: url(../../assets/svg/header-bg.svg);
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  height: 80px;
}
.titleText {
  width: 30%;
  color: #fff;
  text-align: center;
}
.title {
  font-size: 26px;
  font-weight: bolder;
}
.btn-tool {
  border: none;
  background: transparent;
  font-size: 18px;
  padding: 5px 10px;
  cursor: pointer;
  outline: none;
  text-align: left;
}
.user-name {
  margin-left: 20px;
}
</style>
