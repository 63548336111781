<template>
  <div class="echarts-container">
    <div class="view-wrapper">
      <card
        title="数据总览"
        style="grid-column-start: span 2"
        contentHeight="auto"
      >
        <el-row :gutter="20" class="data-row-card">
          <el-col
            :span="4"
            v-for="(item, index) in summarizeViewData"
            :key="index"
            class="data-item-card"
          >
            <div class="data-number-card">{{ item.value }}</div>
            <div class="data-label-card">{{ item.label }}</div>
          </el-col>
        </el-row>
      </card>
      <card title="今日充电统计">
        <div class="content-charge">
          <div class="main-number-charge">{{ todayChargeNum }}次</div>
          <div class="details-charge">
            <div
              class="detail-item-charge"
              v-for="(item, index) in todayChargeTime"
              :key="index"
            >
              <span> {{ item.label }}：</span>
              <span class="detail-value-charge">{{ item.value }}</span>
            </div>
          </div>
        </div>
      </card>

      <card title="当日充电统计">
        <div class="common-chart" id="echarts-3"></div>
      </card>

      <card title="端口分布统计">
        <div class="common-chart" id="echarts-0"></div>
      </card>

      <card title="校区端口使用统计">
        <el-table class="my-table" :data="portUsedStatistics" height="280">
          <el-table-column prop="districtName" label="校区名称" width="auto">
          </el-table-column>
          <el-table-column prop="usedNum" label="端口使用数量" width="150px">
          </el-table-column>
          <el-table-column prop="restNum" label="端口空闲数量" width="150px">
          </el-table-column>
          <el-table-column
            prop="todayChargeNum"
            label="今日充电次数"
            width="150px"
          >
          </el-table-column>
        </el-table>
      </card>

      <card title="动态数据概览">
        <div class="paopao">
          <div
            v-for="(item, index) in summarizeData"
            :key="index"
            class="data-item"
            :style="{
              top: item.top + 'px',
              left: item.left + 'px',
            }"
          >
            <div
              class="halo"
              :style="{
                backgroundColor: item.color,
                '--main-color': item.color,
              }"
            >
              <div class="data-value">{{ item.value }}</div>
              <div class="data-label">{{ item.label }}</div>
            </div>
          </div>
        </div>
      </card>

      <card title="今日TOP3站点">
        <div>
          <el-carousel height="280px" v-if="portUsedStatistics.length" indicator-position="none">
            <el-carousel-item
              v-for="(item, index) in portUsedStatistics.slice(0, 3)"
              :key="index"
            >
              <div class="rank-item">
                <img class="rank-img" :src="rankImgList[index]" />
                <span class="rank-title">
                  {{ item.districtName }}
                </span>
                <div class="info">
                  <span> 充电端口:{{ item.usedNum }} </span>
                  <span> 充电次数: {{ item.todayChargeNum }} </span>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
          <div v-else>数据加载中...</div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
import axios from "axios";

import Card from "@/components/card/index.vue";

var colorArr = [
  "#218de0",
  "#01cbb3",
  "#85e647",
  "#5d5cda",
  "#05c5b0",
  "#c29927",
];
var colorAlpha = [
  "rgba(60,170,211,0.05)",
  "rgba(1,203,179,0.05)",
  "rgba(133,230,71,0.05)",
  "rgba(93,92,218,0.05)",
  "rgba(5,197,176,0.05)",
  "rgba(194,153,39,0.05)",
];
export default {
  components: {
    Card,
  },
  data() {
    return {
      // 校区端口分布统计
      portStatistics: [],
      summarize: {},
      todayHourCharge: {
        xAxis: [],
        value: [],
      },
      // 当日充电统计
      todayChargeNum: 0,
      todayChargeTime: [],
      todayChargeTimeEchart: null,
      portEchart: null,
      todayChargeType: {
        xAxis: [],
        value: [],
      },
      portTotalNumStatis: [],
      // 校区端口使用统计
      portUsedStatistics: [],
      // 统计概要数据-气泡图
      summarizeData: [],
      summarizeViewData: [],
      // 用户折线图
      monthCharges: {
        xAxis: [],
        newuserNum: [],
        chargeNum: [],
      },
      rankImgList: [
        require("../assets/image/NO1.png"),
        require("../assets/image/NO2.png"),
        require("../assets/image/NO3.png"),
      ],
    };
  },
  created() {
    this.queryStatusData();
  },
  mounted() {
    let _this = this;
    setInterval(_this.refreshDrawHourCharge, 8000);
  },
  methods: {
    getRandomPosition(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 请求数据
    async queryStatusData() {
      let _this = this;
      await axios
        .get("https://vehicle-api.clevercoder.ltd/sdvapi/dashboard/summarizing")
        .then(function (res) {
          const response = res.data;
          if (response.code !== 0) {
            console.log("接口出错");
            return;
          }
          const responseData = response.data;
          // 校区端口统计
          let portStatistics = responseData.portStatistics;
          // let portTotalNumStatis = [];
          for (let item of portStatistics) {
            // 校园端口使用统计
            _this.portUsedStatistics.push(item);
            // let portNumStatis = {
            //   value: item.totalNum,
            //   name: item.districtName,
            // };
            // portTotalNumStatis.push(portNumStatis);
          }
          _this.portUsedStatistics = _this.portUsedStatistics.slice(0, 6);
          // _this.portTotalNumStatis = portTotalNumStatis.slice(0, 10);

          _this.setPieDataItems(portStatistics);

          // 当日充电统计
          let todayChargeType = responseData.todayChargeType || [];

          console.log("todayChargeType", todayChargeType);
          let todayChargeNum = 0;
          for (let item of todayChargeType) {
            todayChargeNum = todayChargeNum + item.num;
            _this.todayChargeType.value.push(item.num);
            _this.todayChargeType.xAxis.push(item.type);
            let type = "";
            if (item.type === "充电次数") {
              type = "单次";
            } else if (item.type === "月卡充电次数") {
              type = "月卡";
            } else if (item.type === "线下电卡充电次数") {
              type = "电卡";
            } else if (item.type === "钱包充电次数") {
              type = "魔豆";
            } else if (item.type === "充电券充电次数") {
              type = "充电券";
            }
            if (type !== "") {
              _this.todayChargeTime.push({ label: type, value: item.num });
            }
          }
          _this.todayChargeNum = todayChargeNum;

          // 当日充电趋势
          let todayHourCharge = responseData.todayHourCharge;
          for (let item of todayHourCharge) {
            _this.todayHourCharge.value.push(item.num);
            _this.todayHourCharge.xAxis.push(item.hour);
          }
          _this.drawHourCharge();

          // 统计概要数据-气泡图
          let summarizeData = responseData.summarize;
          _this.summarizeData.push({
            label: "校区数量",
            value: summarizeData.districtNum,
            color: "#58D68D",
            top: 70,
            left: 370,
          });
          _this.summarizeData.push({
            label: "使用数量",
            value: summarizeData.usedPortNum,
            color: "#F4D03F",
            top: 80,
            left: 540,
          });
          _this.summarizeData.push({
            label: "端口总数",
            value: summarizeData.totalPortNum,
            color: "#5DADE2",
            top: 80,
            left: 150,
          });
          _this.summarizeData.push({
            label: "用户数量",
            value: summarizeData.totalUserNum,
            color: "#AF7AC5",
            top: 214,
            left: 140,
          });
          _this.summarizeData.push({
            label: "空闲数量",
            value: summarizeData.restNum,
            color: "#F1948A",
            top: 273,
            left: 300,
          });
          _this.summarizeData.push({
            label: "2天内警告",
            value: summarizeData.alarmNum,
            color: "#E74C3C",
            top: 140,
            left: 260,
          });
          _this.summarizeData.push({
            label: "新增用户",
            value: summarizeData.newUserNum,
            color: "#85C1E9",
            top: 202,
            left: 450,
          });

          _this.summarizeViewData.push({
            label: "校区数量",
            value: summarizeData.districtNum,
          });
          _this.summarizeViewData.push({
            label: "使用数量",
            value: summarizeData.usedPortNum,
          });
          _this.summarizeViewData.push({
            label: "端口总数",
            value: summarizeData.totalPortNum,
          });
          _this.summarizeViewData.push({
            label: "用户数量",
            value: summarizeData.totalUserNum,
          });
          _this.summarizeViewData.push({
            label: "空闲数量",
            value: summarizeData.restNum,
          });
          _this.summarizeViewData.push({
            label: "新增用户",
            value: summarizeData.newUserNum,
          });

          // 用户总量，充电次数
          let monthCharges = responseData.monthCharges;
          for (let item of monthCharges) {
            _this.monthCharges.xAxis.push(item.month);
            _this.monthCharges.newuserNum.push(item.newUserNum);
            _this.monthCharges.chargeNum.push(item.chargeNum);
          }
          // _this.drawMonthCharges()
        });
    },
    drawPortEcharts() {
      var _this = this;
      const option = {
        grid: {
          left: 10,
          top: 10,
          bottom: 10,
          right: 10,
          containLabel: true,
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} ({d}%)",
        },
        legend: {
          show: false,
        },
        polar: {},
        angleAxis: {
          interval: 1,
          type: "category",
          data: [],
          z: 10,
          axisLine: {
            show: false,
            lineStyle: {
              color: "#0B4A6B",
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            interval: 0,
            show: true,
            color: "#0B4A6B",
            margin: 8,
            fontSize: 16,
          },
        },
        radiusAxis: {
          min: 20,
          max: 120,
          interval: 20,
          axisLine: {
            show: false,
            lineStyle: {
              color: "#0B3E5E",
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            formatter: "{value} %",
            show: false,
            padding: [0, 0, 20, 0],
            color: "#0B3E5E",
            fontSize: 16,
          },
          splitLine: {
            lineStyle: {
              color: "#07385e",
              width: 2,
              type: "dashed",
            },
          },
        },
        calculable: true,
        series: [
          {
            stack: "a",
            type: "pie",
            radius: "80%",
            roseType: "radius",
            zlevel: 10,
            startAngle: 100,
            label: {
              normal: {
                formatter: ["{b|{b}}", "{d|{d}%}"].join("\n"),
                rich: {
                  b: {
                    color: "#3bd2fe",
                    fontSize: 14,
                    lineHeight: 20,
                  },
                  d: {
                    color: "#d0fffc",
                    fontSize: 14,
                    height: 20,
                  },
                },
              },
            },
            labelLine: {
              normal: {
                show: true,
                length: 10,
                length2: 45,
                lineStyle: {
                  color: "#0096b1",
                },
              },
              emphasis: {
                show: false,
              },
            },
            data: _this.portTotalNumStatis,
          },
        ],
      };

      console.log("开始渲染端口分布统计");
      console.log(option);
      _this.portEchart = echarts.init(document.getElementById("echarts-0"));
      _this.portEchart.setOption(option);
    },
    // 当日充电统计-折线图刷新
    refreshDrawHourCharge() {
      let _this = this;
      axios
        .get("https://vehicle-api.clevercoder.ltd/sdvapi/dashboard/summarizing")
        .then(function (res) {
          const response = res.data;
          if (response.code !== 0) {
            console.log("接口出错");
            return;
          }
          const responseData = response.data;
          // 当日充电统计
          let todayHourCharge = responseData.todayHourCharge;
          _this.todayHourCharge = {
            xAxis: [],
            value: [],
          };
          console.log("刷新折线图");
          for (let item of todayHourCharge) {
            _this.todayHourCharge.value.push(item.num);
            _this.todayHourCharge.xAxis.push(item.hour);
          }

          _this.drawHourCharge();
          _this.setPieDataItems(responseData.portStatistics);
        });
    },
    setPieDataItems(arr) {
      const portTotalNumStatis = arr.map((e, index) => {
        return {
          value: e.totalNum,
          name: e.districtName,
          itemStyle: {
            borderColor: colorArr[index],
            borderWidth: 2,
            shadowBlur: 20,
            shadowColor: colorArr[index],
            shadowOffsetx: 25,
            shadowOffsety: 20,
            color: colorAlpha[index],
          },
        };
      });
      this.portTotalNumStatis = portTotalNumStatis.slice(0, colorArr.length);
      this.drawPortEcharts();
    },
    // 当日充电统计-折线图
    drawHourCharge() {
      var _this = this;

      const option = {
        title: {
          show: false,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "3%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
            },
          },
          formatter: "{a0}：{c0}",
        },
        legend: {
          show: false,
          data: ["充电量", "充电量2"],
          top: "15%",
          textStyle: {
            color: "#ffffff",
          },
        },
        xAxis: {
          data: _this.todayHourCharge.xAxis,
          axisLine: {
            show: true, //隐藏X轴轴线
            lineStyle: {
              color: "#01FCE3",
            },
          },
          axisTick: {
            show: true, //隐藏X轴刻度
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#dcdcdc", //X轴文字颜色
            },
          },
        },
        yAxis: [
          {
            type: "value",
            nameTextStyle: {
              color: "#dcdcdc",
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: true,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#FFFFFF",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#dcdcdc",
              },
            },
          },
        ],
        series: [
          {
            name: "充电量",
            type: "line",
            yAxisIndex: 0, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
            smooth: true, //平滑曲线显示
            showAllSymbol: true, //显示所有图形。
            symbol: "circle", //标记的图形为实心圆
            symbolSize: 10, //标记的大小
            itemStyle: {
              //折线拐点标志的样式
              color: "#058cff",
            },
            lineStyle: {
              color: "#058cff",
            },
            areaStyle: {
              color: "rgba(5,140,255, 0.2)",
            },
            data: _this.todayHourCharge.value,
          },
        ],
      };
      echarts
        .init(document.getElementById("echarts-3"), "infographic")
        .setOption(option);
    },
  },
};
</script>
<style scoped>
/deep/ .el-table {
  color: #06f2c8;
}
/deep/ .el-table tr {
  background-color: #ffffff00 !important;
}
/deep/ .el-table .el-table__cell {
  text-align: center;
}
/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/ .el-table__header td.el-table__cell,
.el-table__header th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/ .el-table th.el-table__cell {
  background-color: #ffffff00 !important;
}
/deep/ .el-table th.el-table__cell > .cell {
  color: #3185f1;
}
/deep/ .el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: #ffffff00 !important;
}

/deep/ .el-table,
.el-table__expanded-cell {
  background-color: #ffffff00 !important;
}

/deep/ .el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #0e4d72 !important;
}
</style>

<style lang="scss" scoped>
.view-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

.common-chart {
  width: 100%;
  height: 100%;
}

.echarts-container {
  display: flex;
  flex-direction: column; /* 使子元素垂直排列 */
  //background-image: url("./../assets/images/backgroud.gif");
}

.echarts-row {
  // margin-top: 5%;
  display: flex;
  justify-content: space-between; /* 在行内平均分配空间 */
  //margin-bottom: 20px; /* 可选，添加一些底部外边距以分隔行 */
}

.echarts0 {
  width: 37%;
  height: 350px;
  // border: 1px solid #ccc;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  .echarts00 {
    margin-left: 20px;
    margin-top: 20px;
    width: 90%;
    height: 100%;
  }
  .container-main {
    // 设置 flex-grow: 1，让 .container-main 撑满整个容器
    flex-grow: 1;
  }
  .container-header {
    &::before {
      content: "";
      // .container 设置了 position: relative 之后，
      // 这里的 position: absolute 就是相对于 .container 的了
      // 这样之后，top: 0; left: 0; 自然就是 .container 的左上角了
      position: absolute;
      // 给伪类内容设置宽高，这样边框 border 的宽高也就是这个了
      width: 10px;
      height: 10px;
      top: 0;
      left: 0;
      border-top: 2px solid #02a6b5;
      border-left: 2px solid #02a6b5;
    }
    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      top: 0;
      right: 0;
      border-top: 2px solid #02a6b5;
      border-right: 2px solid #02a6b5;
    }
  }
  .container-footer {
    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 0;
      left: 0;
      border-bottom: 2px solid #02a6b5;
      border-left: 2px solid #02a6b5;
    }
    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 0;
      right: 0;
      border-bottom: 2px solid #02a6b5;
      border-right: 2px solid #02a6b5;
    }
  }
}

// .echarts1 {
//   width: 60%;
//   height: 300px;
//   box-sizing: border-box;
// }
.echarts2Border {
  height: 300px;
  box-sizing: border-box;
  .container-title {
    position: absolute;
    // top: 10%;
    left: 3%;
    //bottom: 30%;
    font-size: 18.5px;
    color: #2d8ab7;
    font-weight: bold;
  }
  .echarts2 {
    margin-top: 20px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  .container-main {
    flex-grow: 1;
  }
  .container-header {
    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 90%;
      left: 1.9%;
      border-top: 2px solid #02a6b5;
      border-left: 2px solid #02a6b5;
    }
    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 90%;
      left: 48%;
      border-top: 2px solid #02a6b5;
      border-right: 2px solid #02a6b5;
    }
  }
  .container-footer {
    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 5%;
      left: 1.9%;
      border-bottom: 2px solid #02a6b5;
      border-left: 2px solid #02a6b5;
    }
    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 5%;
      left: 48%;
      border-bottom: 2px solid #02a6b5;
      border-right: 2px solid #02a6b5;
    }
  }
}
.echarts3Border {
  height: 220px;
  box-sizing: border-box;
  .echarts3 {
    margin-top: 30px;
    width: 100%;
    height: 130%;
    box-sizing: border-box;
  }
  .container-main {
    flex-grow: 1;
  }
  .container-header {
    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 90%;
      left: 50.9%;
      border-top: 2px solid #02a6b5;
      border-left: 2px solid #02a6b5;
    }
    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 90%;
      right: 1.9%;
      border-top: 2px solid #02a6b5;
      border-right: 2px solid #02a6b5;
    }
  }
  .container-footer {
    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 5%;
      left: 50.9%;
      border-bottom: 2px solid #02a6b5;
      border-left: 2px solid #02a6b5;
    }
    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 5%;
      right: 1.9%;
      border-bottom: 2px solid #02a6b5;
      border-right: 2px solid #02a6b5;
    }
  }
}
.echarts1Border {
  width: 48%;
  height: 300px;
  box-sizing: border-box;
  .container-title {
    position: absolute;
    top: 10%;
    left: 3%;
    //bottom: 30%;
    font-size: 18.5px;
    color: #2d8ab7;
    font-weight: bold;
  }
  .echarts1 {
    margin-top: 140px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  .container-main {
    flex-grow: 1;
  }
  .container-header {
    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 90%;
      left: 1.9%;
      border-top: 2px solid #02a6b5;
      border-left: 2px solid #02a6b5;
    }
    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 90%;
      left: 48%;
      border-top: 2px solid #02a6b5;
      border-right: 2px solid #02a6b5;
    }
  }
  .container-footer {
    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 10%;
      left: 1.9%;
      border-bottom: 2px solid #02a6b5;
      border-left: 2px solid #02a6b5;
    }
    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 10%;
      left: 48%;
      border-bottom: 2px solid #02a6b5;
      border-right: 2px solid #02a6b5;
    }
  }
}

.echarts4Border {
  height: 300px;
  box-sizing: border-box;
  .echarts00 {
    height: 380px;
  }
  .container-title {
    position: absolute;
    top: 10%;
    left: 3%;
    //bottom: 30%;
    font-size: 18.5px;
    color: #2d8ab7;
    font-weight: bold;
  }
  .echarts2 {
    margin-top: 20px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  .container-main {
    flex-grow: 1;
  }
  .container-header {
    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 90%;
      left: 1.9%;
      border-top: 2px solid #02a6b5;
      border-left: 2px solid #02a6b5;
    }
    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 90%;
      left: 48%;
      border-top: 2px solid #02a6b5;
      border-right: 2px solid #02a6b5;
    }
  }
  .container-footer {
    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 5%;
      left: 1.9%;
      border-bottom: 2px solid #02a6b5;
      border-left: 2px solid #02a6b5;
    }
    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 5%;
      left: 48%;
      border-bottom: 2px solid #02a6b5;
      border-right: 2px solid #02a6b5;
    }
  }
}

.echarts5Border {
  height: 380px;
  box-sizing: border-box;
  .container-title {
    position: absolute;
    // top: 10%;
    left: 52%;
    top: 9%;
    //bottom: 30%;
    font-size: 18.5px;
    color: #2d8ab7;
    font-weight: bold;
  }
  .echarts2 {
    margin-top: 20px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  .container-main {
    flex-grow: 1;
  }
  .container-header {
    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 90%;
      left: 50.9%;
      border-top: 2px solid #02a6b5;
      border-left: 2px solid #02a6b5;
    }
    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 90%;
      right: 1.9%;
      border-top: 2px solid #02a6b5;
      border-right: 2px solid #02a6b5;
    }
  }
  .container-footer {
    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 5%;
      left: 50.9%;
      border-bottom: 2px solid #02a6b5;
      border-left: 2px solid #02a6b5;
    }
    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 5%;
      right: 1.9%;
      border-bottom: 2px solid #02a6b5;
      border-right: 2px solid #02a6b5;
    }
  }
}

.echarts0Border {
  height: 380px;
  box-sizing: border-box;
  .container-title {
    position: absolute;
    // top: 10%;
    left: 52%;
    top: 9%;
    //bottom: 30%;
    font-size: 18.5px;
    color: #2d8ab7;
    font-weight: bold;
  }
  .echarts2 {
    margin-top: 20px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  .container-main {
    flex-grow: 1;
  }
  .container-header {
    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 90%;
      left: 50.9%;
      border-top: 2px solid #02a6b5;
      border-left: 2px solid #02a6b5;
    }
    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 90%;
      right: 1.9%;
      border-top: 2px solid #02a6b5;
      border-right: 2px solid #02a6b5;
    }
  }
  .container-footer {
    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 10%;
      left: 50.9%;
      border-bottom: 2px solid #02a6b5;
      border-left: 2px solid #02a6b5;
    }
    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 10%;
      right: 1.9%;
      border-bottom: 2px solid #02a6b5;
      border-right: 2px solid #02a6b5;
    }
  }
}

.echartsPaoBorder {
  width: 48%;
  // height: 300px;
  box-sizing: border-box;
  .paopao {
    position: relative;
    margin-top: 120px;
    width: 100%;
    height: 43vh;
    overflow: hidden;
  }
  .container-main {
    flex-grow: 1;
  }
  .container-header {
    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: -5%;
      left: 50.9%;
      border-top: 2px solid #02a6b5;
      border-left: 2px solid #02a6b5;
    }
    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: -5%;
      right: 1.9%;
      border-top: 2px solid #02a6b5;
      border-right: 2px solid #02a6b5;
    }
  }
  .container-footer {
    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: -43%;
      left: 50.9%;
      border-bottom: 2px solid #02a6b5;
      border-left: 2px solid #02a6b5;
    }
    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: -43%;
      right: 1.9%;
      border-bottom: 2px solid #02a6b5;
      border-right: 2px solid #02a6b5;
    }
  }
}
.echarts4 {
  width: 60%;
  height: 350px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  .echarts44 {
    margin-left: 20px;
    margin-top: 20px;
    width: 90%;
    height: 100%;
  }
  .container-main {
    // 设置 flex-grow: 1，让 .container-main 撑满整个容器
    flex-grow: 1;
  }
  .container-header {
    &::before {
      content: "";
      // .container 设置了 position: relative 之后，
      // 这里的 position: absolute 就是相对于 .container 的了
      // 这样之后，top: 0; left: 0; 自然就是 .container 的左上角了
      position: absolute;
      // 给伪类内容设置宽高，这样边框 border 的宽高也就是这个了
      width: 10px;
      height: 10px;
      top: 0;
      left: 0;
      border-top: 2px solid #02a6b5;
      border-left: 2px solid #02a6b5;
    }
    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      top: 0;
      right: 0;
      border-top: 2px solid #02a6b5;
      border-right: 2px solid #02a6b5;
    }
  }
  .container-footer {
    &::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 0;
      left: 0;
      border-bottom: 2px solid #02a6b5;
      border-left: 2px solid #02a6b5;
    }
    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: 0;
      right: 0;
      border-bottom: 2px solid #02a6b5;
      border-right: 2px solid #02a6b5;
    }
  }
}
// .paopao {
//     position: relative;
//     width: 50%;
//     height: 50vh;
//     overflow: hidden;
//   }
.paopao {
  // position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.data-item {
  position: absolute;
  width: 100px;
  height: 100px;
  color: white;
  font-weight: bold;
}
.halo {
  position: relative;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.halo::after {
  content: " ";
  position: absolute;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  left: -10px;
  top: -10px;
  opacity: 40%;
  background-color: var(--main-color);
  z-index: -1;
  backdrop-filter: blur(6px);
}

.halo::before {
  content: " ";
  position: absolute;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  left: -20px;
  top: -20px;
  opacity: 20%;
  background-color: var(--main-color);
  z-index: -2;
  box-shadow: -2px 2px 41px 21px var(--main-color);
  backdrop-filter: blur(6px);
}

.data-value {
  font-size: 24px;
}

.data-label {
  font-size: 16px;
}

.data-overview-card {
  padding: 20px;
  background-color: #0e1e3a;
  border-radius: 8px;
  color: #c4e5ff;
}

.el-card {
  background-color: #0e1e3a;
  border-color: #2a3f5c;
  color: #c4e5ff;
}

.header-card {
  color: #c4e5ff;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #2a3f5c;
}

.data-row-card {
  margin-top: 20px;
  color: #c4e5ff;
}

.data-item-card {
  text-align: center;
  border-right: 1px solid #2a3f5c;
  font-size: 18px;
  line-height: 1.5;
}

.data-item-card:last-child {
  border-right: none;
}

.data-number-card {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
}

.data-label-card {
  color: #02a6b5;
  font-size: 16px;
  font-weight: bold;
}

.charging-statistics {
  padding: 20px;
  background-color: #0e1e3a;
  border-radius: 8px;
  color: #c4e5ff;
}

.el-card-charge {
  background-color: #0e1e3a;
  border-color: #2a3f5c;
  color: #c4e5ff;
}

.header-charge {
  color: #49c0ec;
  font-size: 18px;
  font-weight: bold;
}

.content-charge {
  text-align: center;
}

.main-number-charge {
  font-size: 48px;
  font-weight: bold;
  margin: 20px 0;
  color: #c4e5ff;
}

.details-charge {
  display: grid;
  gap: 10px;
  text-align: left;
  grid-template-columns: repeat(3, 200px);
  grid-template-rows: repeat(2, 1fr);
  justify-content: center;
  // justify-items: center;
  // align-items: center;
}

.detail-item-charge {
  font-size: 28px;
  color: #c4e5ff;
}

.detail-value-charge {
  font-size: 32px;
  color: #64e5ce;
  font-weight: bold;
}

// .el-carousel__item:nth-child(2n) {
//   background-color: #99a9bf;
// }

// .el-carousel__item:nth-child(2n + 1) {
//   background-color: #85c1e9;
// }

.rank-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 280px;
  gap: 10px;

  .rank-img {
    width: 100px;
    height: 100px;
  }

  .rank-title {
    color: #ffffff;
    font-weight: bolder;
    font-size: 28px;
  }

  .info {
    color: #c4e5ff;
    font-size: 22px;
  }
}
</style>
